"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Seccl API Documentation
 * #### Welcome to Seccl\'s API reference Hello and welcome! 👋 In this section, we\'ll cover  Our API is organised around REST (Representational State Transfer), which is a particular software architectural style. You can read [more about REST here](https://en.wikipedia.org/wiki/Representational_state_transfer).  Our API uses predictable resource-oriented URLs, accepts form-encoded request bodies and returns JSON-encoded responses. It also uses standard HTTP response codes, authentication and verbs.  You can use the Seccl API in test mode, which doesn\'t affect your live data or interact with other partners. The API route you use determines whether the request is in live mode or test mode.
 *
 * The version of the OpenAPI document: 1.0.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.paths = void 0;
__exportStar(require("./api"), exports);
__exportStar(require("./configuration"), exports);
exports.paths = require("./paths");
