import React from 'react';
import type { Resource } from '@seccl/client';
import type { Permission } from '@seccl/client';
import { hasAccess as hasResourceAccess, hasScope as hasUserScope } from '@seccl/tools/auth';

import { FirmContext } from '../context/firm.context';

/**
 * Custom hook to check if the current user has specific permissions and scopes.
 * @returns An object with methods to check if the user has specific permissions.
 */
export const useScopes = () => {
  const { scopes } = React.useContext(FirmContext);

  /**
   * Checks if the user has a specific permission for a given resource.
   *
   * @param {Resource} resource - The resource to check the permission for.
   * @param {Permission} permission - The specific permission to check.
   * @returns {boolean} True if the user has the permission for the resource, false otherwise.
   */
  const hasScope = React.useCallback(
    (resource: Resource, permission: Permission) => hasUserScope(scopes, resource, permission),
    [scopes]
  );

  /**
   * Checks if the user has either read or read-write access to a specific resource.
   *
   * @param {Resource} resource - The resource to check the access for.
   * @returns {boolean} True if the user has read or read-write access to the resource, false otherwise.
   */
  const hasAccess = React.useCallback((resource: Resource) => hasResourceAccess(scopes, resource), [scopes]);

  return {
    hasScope,
    hasAccess,
  };
};
