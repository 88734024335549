import { openApiControllerFactory } from '@seccl/api-swr';

import { isServer } from '../utils/window';
import { useApiProcessing } from './hooks/useApiProcessing';

const proxyApiPath = '/api/proxy';
const basePath = isServer ? process.env.API_BASE_URL : proxyApiPath;

export const apiFactory = openApiControllerFactory({
  basePath,
  useApiProcessing,
});
