import { endpointIdFactory } from '@seccl/api-swr';
import {
  AccountManagementApi,
  PortfolioGroupManagementApi,
  PortfoliosApi,
  PortfolioTransactionManagementApi,
} from '@seccl/client';

import { apiFactory } from '../../apiFactory';

export const portfolioApiIds = endpointIdFactory('portfolio', PortfoliosApi);

/** Controller factory for accessing endpoint hooks */
export const portfolioApi = apiFactory.createAxiosOpenApiController(portfolioApiIds.controllerKey, PortfoliosApi);
export const portfolioTransactionApi = apiFactory.createAxiosOpenApiController(
  portfolioApiIds.controllerKey,
  PortfolioTransactionManagementApi
);
export const portfolioAccountApi = apiFactory.createAxiosOpenApiController(
  portfolioApiIds.controllerKey,
  AccountManagementApi
);

export const portfolioGroupsApi = apiFactory.createAxiosOpenApiController(
  portfolioApiIds.controllerKey,
  PortfolioGroupManagementApi
);
