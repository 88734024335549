type Arguments<T> = T extends (...args: infer U) => any ? U : never;

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Arguments<T>) => ReturnType<T> | undefined {
  let lastCall = 0;
  return function (...args: Arguments<T>): ReturnType<T> | undefined {
    const now = new Date().getTime();

    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}
