import type { AuthResponseDataScopesInner, Resource } from '@seccl/client';
import { Permission } from '@seccl/client';

import { hasScope } from './hasScope';

/**
 * Check if the user has either read or read-write access to a specific resource.
 */
export const hasAccess = (scopes: AuthResponseDataScopesInner[] = [], resource: Resource): boolean => {
  return hasScope(scopes, resource, Permission.Read) || hasScope(scopes, resource, Permission.ReadWrite);
};
