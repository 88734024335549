import { useFirmId } from '../useFirmId';
import { portfolioApi } from '.';

/**
 * Endpoint hook - GET - returns a paged list of portfolios by name
 * @param name the search term
 * @param pageSize the number of results to return. Defaults to 20 if not provided
 * @returns A `useQuery` hook for requesting data
 */
export const useSearchPortfolios = (name?: string, pageSize: number = 20, params?: Record<string, string>) => {
  const firmId = useFirmId();

  return portfolioApi.portfolioFirmIdGet.useQuery({
    cacheKey: ['firmId', 'name'],
    params: { page: 1, pageSize, name, firmId, ...params },
  });
};
