import React from 'react';
import { datadogRum } from '@datadog/browser-rum-slim';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';

import { ErrorPage } from '../errorPage';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface IErrorBoundaryProps {
  router: NextRouter;
  children: React.ReactElement;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.handleOnErrorAction = this.handleOnErrorAction.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    datadogRum.addError(error, errorInfo);
  }

  handleOnErrorAction = async () => {
    await this.props.router.push('/');
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorPage title="Sorry, something went wrong on our end." onErrorAction={this.handleOnErrorAction} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
