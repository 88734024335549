/**
 * Utility functions for API interfacing.
 * --------------------------------------
 * These functions are designed to facilitate interaction with an API client
 */
import type { AxiosResponse } from 'axios';
import type { AnyPromiseFunction, UnwrapAxiosResponse } from '../types';

/**
 * Checks whether an API response object is an Axios response.
 * @param {AxiosResponse<TResponse> | TResponse} response - The API response object to be checked.
 * @returns {boolean} - A boolean indicating whether the API response object is an Axios response.
 * @template TResponse - The type of data returned by the Axios response.
 */
export const isAxiosResponse = <TResponse>(
  response: AxiosResponse<TResponse> | TResponse
): response is AxiosResponse<TResponse> => {
  return !!(response as AxiosResponse<TResponse>).status;
};

/**
 * Iterates over an OpenAPI TypeScript controller and corrects the scoping issues by adding an initial arrow function to each class method.
 * @param original - The original OpenAPI TypeScript controller to be fixed.
 * @returns A new OpenAPI TypeScript controller with fixed scoping issues.
 * @typeparam T - The type of the OpenAPI TypeScript controller to be fixed.
 */
export const fixGeneratedClient = <T>(original: T): T => {
  const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(original));
  return keys.reduce((p: any, c) => {
    if (c !== 'constructor') {
      p[c] = (...args: any) => (original as any)[c](...args);
    }
    return p;
  }, {} as T) as T;
};

/**
 * Unwraps the data from an axios API response.
 * @async
 * @template TFunc - The function to execute and unwrap the response data from.
 * @param {TFunc} func - The function to execute.
 * @returns {Promise<UnwrapAxiosResponse<TFunc>>} - The unwrapped data from the API response.
 * @throws {Error} - If the API response status is 400 or higher, an error with the response statusText is thrown.
 */
export const unwrapAxiosPromise = async <TFunc extends AnyPromiseFunction>(
  func: TFunc
): Promise<UnwrapAxiosResponse<TFunc>> => {
  const response = await func();
  if (isAxiosResponse(response)) {
    if (response.status >= 400) {
      throw new Error(response.statusText);
    }
    return response.data as UnwrapAxiosResponse<TFunc>;
  }
  return response as UnwrapAxiosResponse<TFunc>;
};
