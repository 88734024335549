import * as React from 'react';
import type { APIProcessingHook } from '@seccl/api-swr';
import { useClearCache } from '@seccl/api-swr';
import { secclErrorFromError, validationErrorsFromError } from '@seccl/tools/errors';
import { useToast } from '@seccl/ui';
import { useRouter } from 'next/router';

import { routes } from '../../routes';

/**
 * Global API Processing hook.
 * - This hook is rendered by every query and mutation that runs through api-swr
 * - It receives a bunch of useful params about the request, and is responsible primarily for client-side error handling
 * @param param0 Some useful data about the incoming request
 * @returns An array of validation errors (or an empty array if no errors)
 */
export const useApiProcessing: APIProcessingHook = ({ error, mode, endpointId }) => {
  const router = useRouter();
  const returnPath = router.asPath;

  const clearCache = useClearCache();

  // handle global errors
  const { dispatchToast } = useToast();

  const handle = React.useCallback(async () => {
    const secclError = secclErrorFromError(error);

    if (secclError?.error.statusCode === 401 || secclError?.error.statusCode === 419) {
      dispatchToast({ title: 'Your session has expired. Please sign in again.', variant: 'error' });

      clearCache();

      router.push(routes.auth.signIn({ returnPath }));
      return;
    }

    const { doNotRedirect } = await import('./doNotRedirectIds');
    if (secclError && mode === 'query' && !doNotRedirect.includes(endpointId)) {
      router.push('/500');
      return;
    }
    if (secclError && mode === 'mutation') {
      return;
    }
  }, [clearCache, dispatchToast, endpointId, error, mode, returnPath, router]);

  React.useEffect(() => {
    handle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, mode]);

  // extract validation errors
  return React.useMemo(() => {
    if (!error || mode === 'query') return [];
    return validationErrorsFromError(error);
  }, [error, mode]);
};
