import React from 'react';
import { Button } from '@seccl/ui/components/button';
import Link from 'next/link';

import styles from './errorSection.module.scss';

/**
 * IErrorSectionProps Interface
 * @property {string} title - The title of the error section.
 * @property {string} [onErrorTitle] - The title of the button to navigate elsewhere or perform an action.
 * @property {string | (() => void)} [onErrorAction] - The URL to navigate to or the action to perform when the button is clicked.
 */
export interface IErrorSectionProps {
  title: string;
  onErrorTitle?: string;
  onErrorAction?: string | (() => void);
}

/**
 * ErrorSection Component
 * A reusable error component for a Next.js app, including a title and a button to navigate elsewhere or perform an action.
 * @param {IErrorSectionProps} props - Props for the ErrorSection component
 * @returns {React.ReactElement} JSX element for the error section
 */
export const ErrorSection: React.FC<IErrorSectionProps> = ({
  title,
  onErrorTitle = 'Back to dashboard',
  onErrorAction = '/',
}) => {
  const buttonProps = typeof onErrorAction === 'string' ? { href: onErrorAction } : { onClick: onErrorAction };

  return (
    <div className={styles.centerAlign}>
      <h1 className={styles.title}>{title}</h1>
      <Button LinkComponent={Link} {...buttonProps} className={styles.button}>
        {onErrorTitle}
      </Button>
    </div>
  );
};
