import React from 'react';
import { BaseListBox, BaseListBoxOption, ListboxCell, SearchInput } from '@seccl/ui/components/autocomplete';

import { useSearchPortfolios } from '../../api/hooks/portfolio';

import styles from './clientSearchInput.module.scss';

interface IControlledClientSearchInputProps {
  onSelectClient: (clientId?: string) => void;
}

/**
 * The refs from the BaseListBox and BaseListBoxOption components are forwarded to ensure the ListboxComponent prop is
 * properly focused and navigated by keyboard, as well as the input itself.
 */
const ClientListboxForwardRef: React.ForwardRefRenderFunction<HTMLDivElement, React.HTMLAttributes<HTMLElement>> = (
  props,
  ref
) => {
  return <BaseListBox headers={['Client ID', 'Name']} {...props} ref={ref} />;
};

const ClientListboxForwardRefProp = React.forwardRef(ClientListboxForwardRef);

export const ClientSearchInput: React.FC<IControlledClientSearchInputProps> = ({ onSelectClient }) => {
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const { data: fetchedClients, isLoading } = useSearchPortfolios(searchTerm);

  return (
    <div className={styles.clientSearchInput}>
      <SearchInput
        aria-label={'Client search'}
        placeholder={'Client search'}
        throttleTime={500}
        getOptionLabel={client => client.name}
        options={fetchedClients?.data?.map(({ name, id }) => ({ id, name: name ?? '' })) ?? []}
        ListboxComponent={ClientListboxForwardRefProp}
        renderOption={(props, { id, name }, state) => (
          <BaseListBoxOption aria-label={name} {...props} key={state.index}>
            <ListboxCell>
              <b>{id}</b>
            </ListboxCell>
            <ListboxCell>{name}</ListboxCell>
          </BaseListBoxOption>
        )}
        noOptionsText={!searchTerm ? 'Start typing' : `No clients found for "${searchTerm}"`}
        loadingText="Searching..."
        loading={isLoading}
        onSelect={c => onSelectClient(c?.id)}
        onTermChange={term => setSearchTerm(term)}
      />
    </div>
  );
};
