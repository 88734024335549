import React from 'react';
import type { AuthResponseDataScopesInner } from '@seccl/client';

interface IFirmContext {
  firmId?: string;
  nodeId?: string;
  scopes?: AuthResponseDataScopesInner[];
  footerCopyright?: string;
  orderExecutionPolicyUrl?: string;
  windowTitle?: string;
  authenticated?: boolean;
}

export const FirmContext = React.createContext<IFirmContext>({
  firmId: undefined,
  nodeId: undefined,
  scopes: undefined,
  footerCopyright: undefined,
  orderExecutionPolicyUrl: undefined,
  windowTitle: undefined,
  authenticated: false,
});
