import { datadogRum } from '@datadog/browser-rum-slim';

import packageJson from '../package.json';

export const injectDatadogRum = async () => {
  if (datadogRum.getInitConfiguration() !== undefined) {
    return;
  }

  const { env = 'staging' } = await fetch('/api/env').then(res => res.json());

  datadogRum.init({
    applicationId: '0e1ffa89-130e-48ee-8f3c-c4c31519638c',
    clientToken: 'pube62a2d255a95536371a1b80a982e1f7d',
    site: 'datadoghq.eu',
    service: 'platform-ui',
    env,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    enablePrivacyForActionName: true,
    allowedTracingUrls: [() => true],
  });
};
