import React from 'react';

import { FirmContext } from '../../context/firm.context';

/**
 * `useFirmId` is a custom React hook that gets the firm ID from the current session data.
 * @returns {string | undefined} - Returns the firm ID of the current session's user, or undefined if there is no session or firm ID.
 */
export const useFirmId = (): string | undefined => {
  const { firmId } = React.useContext(FirmContext);
  return firmId;
};
