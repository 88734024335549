import type { AxiosError } from 'axios';
import axios from 'axios';

/** Type denoting a validation error */
export interface IValidationError {
  /** The attribute of the form data to apply the error to */
  key: string;
  /** The error message */
  message: string;
}

/** Type denoting a validation error from the Seccl API **/
interface SecclValidationError {
  /** The name/path of the field */
  fieldName: string;
  /** The error message to display */
  errorMessage: string;
  /** The error type */
  type: string;
}

/** Type denoting a root Seccl API error */
interface SecclError {
  /** The error data */
  error: {
    /** The error message to display */
    errorMessage: string;
    /** Any validation errors returned */
    fieldErrors: SecclValidationError[];
    /** The HTTP status code */
    statusCode: number;
  };
}

/** Default error string to display */
export const defaultServerError = 'An unexpected error occurred';

/**
 * Utility function for creating an error in Seccl format
 * @param statusCode The HTTP status code of the error (defaults to `500`)
 * @param errorMessage The message to display to the user (defaults to a `defaultServerError`)
 * @returns A valid Seccl error object
 */
export const createSecclError = (statusCode: number = 500, errorMessage = defaultServerError) => {
  return {
    error: { statusCode, fieldErrors: [], errorMessage },
  };
};

/**
 * Takes an unknown error and checks whether it's an Axios error type.
 * @param error The incoming error to check.
 * @returns `true` if it's an Axios error, else `false`. Also type casts the error if true
 */
export const isAxiosError = <TData>(error: unknown): error is AxiosError<TData> => {
  return axios.isAxiosError(error);
};

/**
 * Type guard function to check if the given data is of type `SecclError`
 * @param data The data to be checked
 * @returns A boolean indicating whether the data is of type `SecclError`, Also type casts the error if true
 */
export const isSecclErrorData = (data: unknown): data is SecclError => {
  const castData = data as SecclError;
  return !!castData?.error?.errorMessage && !!castData.error.fieldErrors;
};

/**
 * Converts an error object to a `SecclError` object
 * @param error The error object to be converted
 * @returns A `SecclError` object if error is truthy, otherwise `undefined` if no error
 */
export const secclErrorFromError = (error?: unknown): SecclError | undefined => {
  if (!error) return undefined;
  if (isAxiosError(error)) {
    const errorData = error.response?.data;
    if (isSecclErrorData(errorData)) {
      return errorData;
    }
    return createSecclError(error.response?.status);
  }
  return createSecclError(500);
};

/**
 * Extracts validation errors from an error object
 * @param error The error object to extract validation errors from
 * @returns An array of validation errors (`IValidationError`) if there are any, otherwise an empty array
 */
export const validationErrorsFromError = (error: unknown): IValidationError[] => {
  const secclError = secclErrorFromError(error);
  if (secclError?.error.statusCode === 400) {
    return secclError.error.fieldErrors.map(fe => ({ key: fe.fieldName, message: fe.errorMessage }));
  }
  return [];
};
